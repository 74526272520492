<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">我的订餐</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <div class="content">
      <van-tabs v-model="active" type="card" swipeable sticky>
        <van-tab title="预定">
          <div class="reverse">
            <van-list
              v-if="mealList && mealList.length > 0"
              v-model="mealLoading"
              :finished="mealFinished"
              finished-text="没有更多了"
              :immediate-check="false"
              @load="onMealLoad"
            >
              <!-- openOrder === 2 && JSON.parse(item.products).length === 0 ? '' : toOrder(item.id, item) -->
              <div
                v-for="(item, index) in mealList"
                :key="index"
                class="dish-card"
                @click.self="toOrder(item.id, item)"
              >
                <div class="title">
                  <div class="day">{{ item.date}}({{ week(item.date) }})</div>
                  <div class="section">
                    <!--                  <div class="btn" @click="toOrder">预定</div>-->
                    <span>{{ section(item.type) }}</span>
                  </div>
                </div>
                <div class="card">
                  <div class="eat-time">
                    <div>就餐时间</div>
                    <div>{{ subTime(item.mealStart) + '~' + subTime(item.mealEnd) }}</div>
                  </div>
                  <div class="eat-time">
                    <div>预定截止时间</div>
                    <div>{{ item.endTime }}</div>
                  </div>
                  <div class="eat-time">
                    <div>就餐类型</div>
                    <div>
                      <van-button
                        size="small"
                        type="default"
                        :class="{active:!item.flag}"
                        @click="item.flag =false"
                      >堂食</van-button>
                      <van-button
                        v-if="isOutsideOrder"
                        type="default"
                        size="small"
                        :class="{active:item.flag}"
                        @click="item.flag =true"
                      >外送</van-button>
                    </div>
                  </div>
                  <div v-if="item.flag" class="eat-time">
                    <div>
                      <input v-model="msg" type="text" style="border:none" placeholder="请输入地址" />
                    </div>
                  </div>
                  <div class="common-flex footer">
                    <div class="btn" @click.stop="toOrder(item.id, item,item.flag)">订餐</div>
                  </div>
                </div>
              </div>
            </van-list>
            <van-empty v-else description="暂无数据" style="padding-top: 40%" />
          </div>
        </van-tab>
        <van-tab title="订单">
          <van-list
            v-if="mealedList && mealedList.length > 0"
            v-model="mealedLoading"
            :finished="mealedFinished"
            finished-text="没有更多了"
            :immediate-check="false"
            @load="onMealedLoad"
          >
            <div class="reverse">
              <div v-for="(item, index) in mealedList" :key="index" class="dish-card">
                <div class="title">
                  <div class="day">{{ item.mealDate }}({{week(item.mealDate)}})</div>
                  <div class="section">
                    <span>{{ section(item.mealType) }}</span>
                  </div>
                </div>
                <div class="card">
                  <div
                    v-if="item.status === 0 && now(item.mealEnd) && item.mode !=='take_out_meal'"
                    class="eat-time"
                    style="padding-top: 0"
                    @click="openQR(item.id)"
                  >
                    <div>取餐码</div>
                    <div>
                      <van-icon name="qr" size="20" />
                    </div>
                  </div>
                  <div
                    class="eat-time"
                    :style="{paddingTop: !item.products || item.products.length <= 0 ? '.15rem' : '.5rem'}"
                  >
                    <div style="display: flex;align-items: center">
                      <div>就餐时间</div>
                      <div style="margin-left: .3rem;">
                        <van-tag
                          size="medium"
                          :color="item.status === 0 ? '#ee0a24' : item.status === 1 ? '#07c160' : '#909399' "
                        >{{ item.status === 0 ? '未就餐' : item.status === 1 ? '已就餐' : item.status === 3 ? '未支付' : '已取消' }}</van-tag>
                      </div>
                    </div>
                    <div>{{ subTime(item.mealStart) + '~' + subTime(item.mealEnd) }}</div>
                  </div>
                  <div class="eat-time">
                    <div>预定时间</div>
                    <div>{{ item.createTime }}</div>
                  </div>
                  <div v-if="item.products && item.products.length > 0" class="dishes orders">
                    <div v-for="items in item.products" :key="items.id" class="common-flex dish">
                      <div class="common-center">
                        {{ items.name }}
                        <van-tag
                          plain
                          type="primary"
                          color="#FF9460"
                          size="medium"
                          style="margin-left: .5rem"
                        >¥{{ (items.price / 100).toFixed(2) }}</van-tag>
                      </div>
                      <div>数量：{{ items.num }}</div>
                    </div>
                  </div>
                  <div v-if="item.mode == 'take_out_meal'" class="eat-time">
                    <div>配送地址</div>
                    <div>{{ item.address }}</div>
                  </div>
                  <div class="common-flex footer" style="justify-content: space-between">
                    <div>总价：¥{{ (item.amount/100).toFixed(2) }}</div>
                    <div v-if="item.status === 0 && now(item.mealEnd)" class="eat-time cancel-end">
                      <van-button
                        plain
                        round
                        type="info"
                        size="small"
                        color="red"
                        class="cancel"
                        @click="handleCancel(item.id)"
                      >取消订单</van-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
          <van-empty v-else description="暂无数据" style="padding-top: 40%" />
          <!--          <van-overlay :show="showQR" class-name="show-qs" duration="0" @click="showQR = false">-->
          <!--            <img :src="generateQR" alt="" style="width: 20rem">-->
          <!--          </van-overlay>-->
        </van-tab>
      </van-tabs>
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import moment from "moment";
import {
  mealCancel,
  mealedAllList,
  mealOrder,
  unMealList,
  getUserSystemSetupAPI,
  deleteUnPaymentOrderAPI,
} from "@/api/order";
import { Dialog, Toast } from "vant";

export default {
  name: "Order",
  data() {
    return {
      // 是否显示外送
      isOutsideOrder: false,
      // 是否显示配送地址
      isAddress: false,
      showQR: false,
      generateQR: "",
      userId: "",
      mealedLoading: false,
      mealedFinished: false,
      mealLoading: false,
      mealFinished: false,
      show: false,
      mealPagination: {
        current: 1,
        size: 10,
        total: 0,
      },
      mealedPagination: {
        current: 1,
        size: 10,
        total: 0,
      },
      mealShow: true,
      active: 0,
      cancel,
      mealList: [],
      mealedList: [],
      // 是否外送
      isDelivery: false,
      // 地址
      msg: "",
    };
  },
  computed: {
    now() {
      return (time) => {
        const second = moment().format("YYYY-MM-DD HH:mm:ss");
        return moment(second).isBefore(time, "second");
      };
    },
    date() {
      return (day) => {
        const today = moment().format("YYYY-MM-DD");
        const yesterday = moment(moment().add(1, "days")).format("YYYY-MM-DD");
        if (day === today) return "今天";
        if (day === yesterday) return "明天";
        return day;
      };
    },
    week() {
      return (time) => {
        const date = moment(time).day();
        const list = {
          1: "星期一",
          2: "星期二",
          3: "星期三",
          4: "星期四",
          5: "星期五",
          6: "星期六",
          0: "星期日",
        };
        return time && list[date];
      };
    },
    section() {
      return (type) => {
        const list = {
          breakfast: "早",
          lunch: "中",
          dinner: "晚",
        };
        return type && list[type];
      };
    },
    totalFee() {
      return (list) => {
        let total = 0;
        list &&
          list.length > 0 &&
          list.map((item) => {
            total += (item.price / 100) * item.num;
            return total;
          });
        return total.toFixed(2);
      };
    },
    subTime() {
      return (time) => {
        return time.substr(-8);
      };
    },
  },
  created() {
    const { userId } = this.$store.getters;
    const { current, size } = this.mealPagination;
    const { active } = this.$route.params ? this.$route.params : "";
    if (active) this.active = active;
    this.userId = userId;
    this.fetch({ userId, current, size });
    this.deleteUnPaymentOrderFn(userId);
    this.getUserSystemSetupFn();
  },
  methods: {
    // 获取取餐码
    async openQR(id) {
      // console.log(id, 123123);
      this.$router.push({
        name: "MealCode",
        params: { id },
      });
    },
    // 取消订单
    handleCancel(id) {
      const { userId, fetch, getMealedList } = this;
      Dialog.confirm({
        title: "提示",
        message: "确定取消订单吗",
      })
        .then(async () => {
          this.mealPagination = this.mealedPagination = {
            current: 1,
            size: 10,
            total: 0,
          };
          const { current, size } = this.mealPagination;
          const res = await mealCancel(id);
          const { code, message } = res;
          if (code === 0) {
            Toast.success("取消成功");
            this.mealList = this.mealedList = [];
            fetch({ userId, current, size });
            getMealedList({ userId, current, size });
            this.active = 1;
          } else {
            console.log(message);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    onMealLoad() {
      const { userId } = this;
      const { current, size, total } = this.mealPagination;
      if (current * size >= total) {
        this.mealFinished = true;
      } else {
        this.fetch({ userId, current: current + 1, size });
      }
    },
    onMealedLoad() {
      const { userId } = this;
      const { current, size, total } = this.mealedPagination;
      if (current * size >= total) {
        this.mealedFinished = true;
      } else {
        // console.log(212);
        this.getMealedList({ userId, current: current + 1, size });
      }
    },
    async fetch(params) {
      const { mealList } = this;
      mealList.length === 0 && (this.show = true);
      const res = await unMealList(params);
      const { code, data, message } = res;
      // console.log(data);
      if (code === 0) {
        this.mealList = [...mealList, ...data.records];
        this.mealPagination = {
          current: data.current,
          size: data.size,
          total: data.total,
        };
        this.mealList.forEach((item) => {
          this.$set(item, "flag", false);
        });
        // console.log(this.mealList);

        setTimeout(() => {
          this.mealLoading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
    },
    async getMealedList(params) {
      const { mealedList } = this;
      mealedList.length === 0 && (this.show = true);
      const res = await mealedAllList(params);
      const { code, data, message } = res;
      if (code === 0) {
        this.mealedList = [...mealedList, ...data.records];
        this.mealedPagination = {
          current: data.current,
          size: data.size,
          total: data.total,
        };
        setTimeout(() => {
          this.mealedLoading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
      // console.log(this.mealedList, 1111);
    },
    toHome() {
      this.$router.push({ name: "Home" });
    },
    toOrder(id, item, flag) {
      this.$store.dispatch("user/getInfo").then(() => {
        const { userId, fetch, getMealedList } = this;
        const { openOrder } = this.$store.getters;
        const now = moment().format("YYYY-MM-DD HH:mm:ss");
        const before = moment(item.endTime).isBefore(now, "second");
        if (before) {
          Toast("已过订餐时间，订餐失败！");
          return;
        }
        if (flag && this.msg.trim() == "") {
          Dialog.confirm({
            title: "提示",
            message: "请输入地址",
          });
          return;
        }
        const products = item.products || [];
        if (openOrder === 0 && products.length === 0) {
          this.mealPagination = this.mealedPagination = {
            current: 1,
            size: 10,
            total: 0,
          };
          const { current, size } = this.mealPagination;
          Dialog.confirm({
            title: "提示",
            message: "确定预定此餐吗",
          })
            .then(async () => {
              const originalCost = 0;
              const amount = 0;
              const reduceCost = 0;
              const order = {
                mealId: item.id,
                originalCost,
                amount,
                reduceCost,
                products: [],
              };
              const res = await mealOrder(userId, { order });
              const { code, message } = res;
              if (code === 0) {
                Toast.success("预定成功");
                this.mealList = this.mealedList = [];
                fetch({ userId, current, size });
                getMealedList({ userId, current, size });
                this.active = 1;
              } else {
                console.log(message);
              }
            })
            .catch(() => {
              // on cancel
            });
        } else {
          this.$router.push({
            name: "OrderDetail",
            params: { id, msg: this.msg, flag },
          });
        }
      });
    },
    clickFn(index) {
      this.mealList[index].flag = !this.mealList[index].flag;
      // console.log(this.mealList[index].flag);
    },
    async getUserSystemSetupFn() {
      const organizationId = JSON.parse(
        sessionStorage.getItem("personInfo")
      ).organizationId;
      const res = await getUserSystemSetupAPI(organizationId);
      if (res.code === 0 && res.data !== null) {
        if (JSON.parse(JSON.stringify(res.data.setting)) !== "{}") {
          const data = JSON.parse(res.data.setting).dev_set;
          data.forEach((item) => {
            if (item.title === "消费配置") {
              item.switchList.forEach((citem) => {
                if (citem.title === "订餐外送") {
                  this.isOutsideOrder = citem.switchFlag;
                }
              });
            }
          });
        }
      }
    },
    deleteUnPaymentOrderFn(userId) {
      deleteUnPaymentOrderAPI(userId)
        .then((res) => {
          if (res.code === 0) {
            const { userId } = this.$store.getters;
            const { current, size } = this.mealPagination;
            this.getMealedList({ userId, current, size });
          }
        })
        .catch(() => {
          
        });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    //position: relative;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  & > .content {
    padding: 4rem 0 0;
    .reverse {
      //margin-top: 1rem;
      //padding: 0 1rem 7rem;
      //position: fixed;
      //width: 100%;
      //box-sizing: border-box;
      //height: 100%;
      //overflow: scroll;
      .dish-card {
        //margin-bottom: 1.25rem;
        //border-bottom: 2px dashed #ccc;
        padding-bottom: 1rem;
        .title {
          color: black;
          display: flex;
          justify-content: space-between;
          padding: 0.5rem;
          .day {
            width: 50%;
          }
        }
      }
    }
    .title {
      display: flex;
      align-items: center;
      //padding: 0.5rem 1.5rem .4rem;
      padding-bottom: 0.5rem;
      text-align: center;
      & > .section {
        display: flex;
        justify-content: flex-end;
        span {
          padding: 0.1rem;
          border: 1px solid #1989fa;
          color: #1989fa;
          border-radius: 50%;
          font-size: 0.875rem;
          background-color: #fff;
          margin-left: 2rem;
          width: 1.3rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      div {
        width: 33.3%;
        font-weight: 600;
      }
    }
    .card {
      background-color: #fff;
      border-radius: 0.625rem;
      padding: 0.625rem 1rem;
      box-shadow: 0 0 0.875rem #dcdcdc;
      .cancel-end {
        display: flex;
        justify-content: flex-end !important;
        padding-top: 0 !important;
      }
      & > .dishes {
        border-bottom: 1px dashed #ccc;
        .dish {
          padding: 0.5rem 0;
          justify-content: space-between;
          .common-center {
            display: flex;
            align-items: center;
          }
        }
        //display: flex;
      }
      & > .eat-time {
        border-bottom: 1px dashed #ccc;
        padding: 0.5rem 0;
        //color: #07c160;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .active {
          background: skyblue;
          color: white;
        }
      }

      .footer {
        padding-top: 0.5rem;
        //border-top: 1px dashed #ccc;
        ::v-deep .cancel {
          font-size: 0.875rem !important;
        }
        .btn {
          background-color: #ff9460;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 0.3rem;
          font-size: 0.875rem;
          font-weight: normal;
          display: inline-block;
        }
      }
    }
    .show-qs {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .van-tabs__nav--card {
  border: 1px solid #607483;
  height: 2.5rem;
  border-radius: 1.25rem;
  overflow: hidden;
}
::v-deep .van-tab.van-tab--active {
  // background-color: #607483 !important;
  border: 1px solid red;
}
::v-deep .van-tab {
  color: black;
  // border-right: 1px solid #607483;
}
::v-deep .dialog-footer {
  display: flex;
  justify-content: space-around;
  padding: 1rem 1.875rem;
}
::v-deep .van-popup--center {
  border-radius: 1rem;
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}
::v-deep .van-stepper__input {
  font-size: 1rem;
}
::v-deep .van-list {
  margin-top: 1rem;
  padding: 0 1rem 7rem;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  overflow: scroll;
}
</style>
