import request from '@/utils/request'

export function unMealList(params) {
  return request({
    url: `/icbc/meal/pagination`,
    method: 'get',
    params
  })
}

export function mealedAllList(params) {
  return request({
    url: `/icbc/meal/records/pagination`,
    method: 'get',
    params
  })
}

export function mealOrder(data) {
  return request({
    url: `/icbc/meal/order`,
    method: 'post',
    data
  })
}

export function mealDetail(id) {
  return request({
    url: `/icbc/meal/${id}`,
    method: 'get'
  })
}

export function mealCancel(id) {
  return request({
    url: `/icbc/meal/orders/${id}/cancel`,
    method: 'post'
  })
}

export function getQR(id) {
  return request({
    url: `/icbc/order/${id}/mealCode`,
    method: 'get'
  })
}


// 提交订单API
export function submitAPI(data) {
  return request({
    url: `/icbc/meal/order`,
    method: 'post',
    data
  })
}

// 获取商户系统设置接口
export function getUserSystemSetupAPI(id) {
  return request({
    url: `/organization/setting/${id}`,
    method: 'get',
  })
}

// 数币订餐   /icbc/h5/canOrderDcepPay
export function canOrderDcepPayAPI(data) {
  return request({
    url: `/icbc/h5/canOrderDcepPay`,
    method: 'post',
    data
  })
}

// 删除未支付订餐  icbc/meal/records/cancel/{userId}
export function deleteUnPaymentOrderAPI(userId) {
  return request({
    url: `/icbc/meal/records/cancel/?userId=${userId}&phone=`,
    method: 'post',
  })
}
